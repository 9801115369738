import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform } from 'react-native';
import { useForm } from 'assets/form';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { PatientUnderCareForm } from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientRecordState,
} from './patient-store';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import { PersonalInfoModal } from './components/record-under-care-info/PatientPersonalInfoModal';
import {
  PatientRecordDto,
  UpdateAddressDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { UseFormReturn } from 'react-hook-form';
import { useUserState } from '../../../store/user-store';
import patientService from '../../../api/patient-service';
import { PersonalInformationComponent } from './components/account-information/PersonalInformationComponent';
import {
  DateTimeFormat,
  formatDateStringAsISO,
  formatISODateAsString,
} from '../../../common/form-utils';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { PersonalInfoBottomSheet } from './components/record-under-care-info/PatientPersonalInfoBottomSheet';

export const PersonalInformation: FunctionComponent<
  PropsWithChildren<PersonalInformationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const { user } = useUserState();
  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {},
  });
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.PersonalInfo,
  );
  const [viewMode, switchViewMode] = useState(false);
  const nativeRef = React.useRef<BottomSheetModal>(null);
  const [showModal, setShowModal] = useState(false);
  const { status, error } = usePatientRecordState();

  useEffect(() => {
    loadPatientRecord();
  }, []);

  const loadPatientRecord = async () => {
    if (user && user.patientRecordId) {
      setPatientRecord(
        await patientService.findPatientRecord(user.patientRecordId),
      );
    }
  };

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    whichDetailsToEdit(editDetail);
    setVisibility(true);
    if (patientRecord) {
      methods.setValue('first_name', patientRecord.first_name);
      methods.setValue('last_name', patientRecord.last_name);
      methods.setValue(
        'date_of_birth',
        formatISODateAsString(
          patientRecord.date_of_birth,
          DateTimeFormat.USDateFormat,
        ),
      );
      methods.setValue('gender', patientRecord.gender);
      if (patientRecord.address) {
        methods.setValue(
          'address1',
          patientRecord.address.address1 ? patientRecord.address.address1 : '',
        );
        methods.setValue(
          'address2',
          patientRecord.address.address2 ? patientRecord.address.address2 : '',
        );
        methods.setValue(
          'city',
          patientRecord.address.city ? patientRecord.address.city : '',
        );
        methods.setValue(
          'state',
          patientRecord.address.state ? patientRecord.address.state : '',
        );
        methods.setValue(
          'country',
          patientRecord.address.country ? patientRecord.address.country : '',
        );
        methods.setValue(
          'postal_code',
          patientRecord.address.postal_code
            ? patientRecord.address.postal_code
            : '',
        );
      } else {
        methods.setValue('address1', '');
        methods.setValue('address2', '');
        methods.setValue('city', '');
        methods.setValue('state', '');
        methods.setValue('country', 'US');
        methods.setValue('postal_code', '');
      }
    } else {
      throw new Error('No patient record found');
    }
    return methods;
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    setWhichEditDetails(editType);
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    if (patientRecord)
      setPatientRecord(
        await patientService.updatePatientRecord(patientRecord.id, updatePR),
      );
  };

  const handleSubmit = () => {
    const updatedFormValues = methods.getValues();

    const addressDto: UpdateAddressDto = {};
    if (updatedFormValues.address1 !== '')
      addressDto.address1 = updatedFormValues.address1;
    if (updatedFormValues.address2 !== '')
      addressDto.address2 = updatedFormValues.address2;
    if (updatedFormValues.city !== '') addressDto.city = updatedFormValues.city;
    if (updatedFormValues.state !== '')
      addressDto.state = updatedFormValues.state;
    if (updatedFormValues.postal_code !== '')
      addressDto.postal_code = updatedFormValues.postal_code;
    if (
      updatedFormValues.address1 !== '' &&
      updatedFormValues.city !== '' &&
      updatedFormValues.state !== '' &&
      updatedFormValues.postal_code !== '' &&
      updatedFormValues.country !== ''
    ) {
      addressDto.country = updatedFormValues.country;
    }

    const isAddressComplete =
      updatedFormValues.address1 &&
      updatedFormValues.city &&
      updatedFormValues.country &&
      updatedFormValues.state &&
      updatedFormValues.postal_code
        ? true
        : false;

    const updatePR: UpdatePatientRecordDto = {
      first_name: updatedFormValues.first_name,
      last_name: updatedFormValues.last_name,
      date_of_birth: formatDateStringAsISO(
        updatedFormValues.date_of_birth,
        DateTimeFormat.USDateFormat,
      ),
      gender: updatedFormValues.gender,
      address: isAddressComplete ? addressDto : null,
    };
    updatePatientRecord(updatePR).catch((error) => {
      setVisibility(false);
      alert(error);
    });
    setVisibility(false);
  };

  const setVisibility = (visible: boolean) => {
    changeViewMode(true);
    if (Platform.OS === 'web') {
      setShowModal(visible);
    } else {
      if (!visible) {
        nativeRef.current?.dismiss();
      } else {
        nativeRef.current?.present();
      }
    }
  };

  const changeViewMode = (editMode: boolean) => {
    switchViewMode(editMode);
  };

  return (
    <ScreenContainer>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        {patientRecord && (
          <PersonalInformationComponent
            patientRecord={patientRecord}
            onEditPress={() =>
              onEditPress(PatientUnderCareEditDetailsEnum.PersonalInfo, methods)
            }
            form={methods}
          ></PersonalInformationComponent>
        )}
        {editDetails === PatientUnderCareEditDetailsEnum.PersonalInfo &&
          patientRecord &&
          (Platform.OS === 'web' ? (
            <PersonalInfoModal
              title={getText('update-personal-info')}
              showModal={showModal}
              setVisibility={setVisibility}
              handleRemovePatientUnderCare={() => {}}
              patientRecord={patientRecord}
              changeViewMode={changeViewMode}
              viewMode={viewMode}
              handleSave={handleSubmit}
              onEditPress={onEditPress}
              whichDetailsToEdit={whichDetailsToEdit}
              editDetails={editDetails}
              form={methods}
            ></PersonalInfoModal>
          ) : (
            <PersonalInfoBottomSheet
              title={getText('update-personal-info')}
              nativeRef={nativeRef}
              setVisibility={setVisibility}
              handleRemovePatientUnderCare={() => {}}
              changeViewMode={changeViewMode}
              viewMode={viewMode}
              patientRecord={patientRecord}
              handleSave={handleSubmit}
              onEditPress={onEditPress}
              whichDetailsToEdit={whichDetailsToEdit}
              editDetails={editDetails}
              form={methods}
            ></PersonalInfoBottomSheet>
          ))}
      </Form>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  typeaheadContainer1: {
    marginBottom: theme.getSpacing(2),
    zIndex: 2,
  },
  typeaheadContainer2: {
    marginBottom: theme.getSpacing(2),
    zIndex: 1,
  },
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.error[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lineStyle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  requestHeaderStyle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '800',
    color: theme.palette.gray[900],
  },
  emailTextStyle: {
    color: theme.palette.gray[900],
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
  },
  requestContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  paddingModalHeader: {
    padding: theme.getSpacing(1),
  },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  declineButtonStyle: { marginEnd: theme.getSpacing(2), width: '50%' },
  authorizationContainer: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  rightSpacing: { marginRight: theme.getSpacing(0.5) },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  containerText: {
    color: theme.palette.gray[900],
    fontSize: 14,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    textAlign: 'center',
  },
  requestApprovedText: {
    color: theme.palette.gray[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  contentAlignmentCenter: { alignContent: 'center' },
  rowDirection: { flexDirection: 'row' },
}));

type PersonalInformationProps = NativeStackScreenProps<
  AccountStackParamList,
  'personal-information'
>;
