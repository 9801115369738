import { Platform } from 'react-native';
import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import { Location } from '../types';
import { RxStatus } from '@digitalpharmacist/prescription-service-client-axios';
import { getText } from 'assets/localization/localization';
import PatientService from '../api/patient-service';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

/**
 * Calculates the haversine distance between point A, and B.
 * @param {Location} first point A
 * @param {Location} second point B
 * @param {boolean} isMiles If we are using miles, else km.
 */
export const haversineDistance = (
  first: Location,
  second: Location,
  unit: 'km' | 'mi' = 'mi',
) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(second.latitude!, first.latitude!);
  const dLon = distance(second.longitude!, first.longitude!);

  const lat1 = toRadian(first.latitude!);
  const lat2 = toRadian(second.latitude!);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (unit === 'mi') {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

export const notImplementedAlert = () => {
  alert('Under construction. Exciting things coming soon!');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace('+1', '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const isMobileOrTabletBrowser = async () => {
  const deviceType = await getDeviceTypeAsync();
  return (
    Platform.OS === 'web' &&
    (deviceType === DeviceType.PHONE || deviceType === DeviceType.TABLET)
  );
};

export const isMobileBrowser = async () => {
  const deviceType = await getDeviceTypeAsync();
  return Platform.OS === 'web' && deviceType === DeviceType.PHONE;
};

export const getRefillErrorStatus = (errorStatus: string) => {
  switch (errorStatus) {
    case RxStatus.NoRefills:
      return getText('no-refills');
    case RxStatus.Expired:
      return getText('expired');
    case RxStatus.TooSoon:
      return getText('too-soon');
    case RxStatus.NotFound:
      return getText('not-found');
    case RxStatus.Controlled:
      return getText('controlled');
    case RxStatus.Narcotic:
      return getText('narcotic');
    case RxStatus.Transferred:
      return getText('transferred');
    case RxStatus.Discontinued:
      return getText('discontinued');
    case RxStatus.OnHold:
      return getText('on-hold');
    case RxStatus.Deleted:
      return getText('deleted');
    case RxStatus.Fillable:
      return getText('fillable');
    case RxStatus.PartiallyFilled:
      return getText('partially-filled');
    case RxStatus.OutOfStock:
      return getText('out-of-stock');
    case RxStatus.SpecialOrder:
      return getText('special-order');
    case RxStatus.Deceased:
      return getText('deceased');
    default:
      return '';
  }
};

export const tagStyles = {
  p: {
    lineHeight: 22.4,
  },

  ul: {
    lineHeight: 22.4,
    listStyleType: 'disc',
    paddingLeft: 18,
  },

  ol: {
    lineHeight: 22.4,
    listStyleType: 'decimal',
    paddingLeft: 18,
  },

  li: {
    lineHeight: 22.4,
  },

  h1: {
    lineHeight: 22.4,
    fontSize: 27,
    fontWeight: '700',
    marginBottom: 42,
  },

  h2: {
    lineHeight: 22.4,
    fontSize: 21,
    fontWeight: '400',
    marginBottom: 33,
  },

  h3: {
    lineHeight: 22.4,
    fontSize: 18,
    fontWeight: '400',
    marginBottom: 27,
  },

  h4: {
    lineHeight: 22.4,
    fontSize: 12,
    fontWeight: '700',
    marginBottom: 24,
  },
} as any;

export const classesStyles = {
  Bold: {
    fontWeight: '600',
  },

  Underline: {
    textDecorationLine: 'underline',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'black',
  },
} as any;

export const createLprIfNotExist = async (
  locationPatientId: string | undefined,
  locationId: string,
  patientRecord: PatientRecordDto,
) => {
  if (!locationPatientId) {
    const newPatientRecord =
      await PatientService.patientRecordInitLocationPatientRecord(
        patientRecord.id,
        {
          location_id: locationId,
        },
      );

    const finalLocationPatientId =
      newPatientRecord.location_patient_records.find(
        (record) => record.location_id === locationId,
      )?.id;

    if (!finalLocationPatientId) {
      throw new Error('Failed to create location patient record');
    }

    return finalLocationPatientId;
  } else {
    return locationPatientId;
  }
};
