import { Text, View } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { makeStyles } from 'assets/theme';
import React from 'react';
import { DocumentView } from '../document-view';
import { VUCA_API_BASE_URL } from '../../common/constants';

export const EducationInfoSheet = ({ medication }: any) => {
  return (
    medication &&
    medication.medication_guides && (
      <DocumentView
        src={`${VUCA_API_BASE_URL}${medication.patient_educationsheet_pdf_download_uri}`}
        streaming={true}
        style={{ flex: 1 }}
      />
    )
  );
};

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: 24,
    fontWeight: '500',
    marginBottom: theme.getSpacing(1),
  },
  copyright: {
    fontSize: 12,
    fontWeight: '500',
    paddingTop: theme.getSpacing(2),
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
  },
}));
