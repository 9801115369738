import theme, { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View, StyleProp, TextStyle, ViewStyle, Platform } from 'react-native';

export const NotificationBadge: FunctionComponent<
  PropsWithChildren<NotificationBadgeProps>
> = ({
  count,
  color = theme.palette.white,
  backgroundColor = theme.palette.error[600],
  textStyle,
  badgeStyle,
}) => {
  const styles = useStyles();
  return (
    <View style={[styles.badge, badgeStyle, { backgroundColor }]}>
      <Text
        testID={NotificationBadgeTestIDs.badgeCount}
        style={[
          styles.badgeText,
          textStyle,
          {
            color,
          },
        ]}
      >
        {count}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {
    position: 'absolute',
    top: -5,
    right: 45,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  badgeText: {
    paddingVertical: Platform.OS === 'android' ? theme.getSpacing(1) : 3,
    paddingHorizontal: theme.getSpacing(0.5),
    fontSize: 12,
    lineHeight: 15,
    fontWeight: '500',
    textAlign: 'center',
    alignSelf: 'center',
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 4,
    borderColor: theme.colors.brandedPrimary,
  },
}));

export interface NotificationBadgeProps {
  backgroundColor?: string;
  color?: string;
  count: number;
  textStyle?: StyleProp<TextStyle>;
  badgeStyle?: StyleProp<ViewStyle>;
}

export const NotificationBadgeTestIDs = {
  badgeCount: 'notification-badge-count',
};
