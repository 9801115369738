import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import * as validate from '@digitalpharmacist/validation-dp';
import {
  DateTimeFormat,
  formatDate,
  formatDateStringAsISO,
} from '../../../../../common/form-utils';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Button } from 'assets/components/button';
import {
  PatientUnderCareForm,
  updatePatientUnderCareForm,
} from '../../patient-actions';
import { usePatientRecordState } from '../../patient-store';
import {
  Gender,
  PatientRecordDto,
  RecordUnderCareDto,
  UpdateAddressDto,
  UpdatePatientRecordDto,
  UpdateRecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { Text } from 'assets/components/text';
import { FunctionComponent, PropsWithChildren } from 'react';
import patientService from '../../../../../api/patient-service';
import { UseFormReturn } from 'react-hook-form';
import { Address } from '../FormAddress';

export const EditPersonalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord?: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    relationshipProps?: Array<{ label: string; value: string }>;
    record?: RecordUnderCareDto;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({
  record,
  patientRecord,
  changeViewMode,
  relationshipProps,
  handleSave,
  form,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error } = usePatientRecordState();
  const formValues = form.getValues();

  const handleSubmit = async () => {
    updatePatientUnderCareForm(form.getValues());
    const updatedFormValues = form.getValues();
    changeViewMode(false);
    if (record?.relationship !== formValues.relationship) {
      const updateRecordUnderCare: UpdateRecordUnderCareDto = {
        relationship: formValues.relationship,
      };
      if (patientRecord && record)
        await patientService.recordUnderCareUpdate(
          patientRecord.id,
          record.id,
          updateRecordUnderCare,
        );
    }
    const addressDto: UpdateAddressDto = {};
    if (updatedFormValues.address1 !== '')
      addressDto.address1 = updatedFormValues.address1;
    if (updatedFormValues.address2 !== '')
      addressDto.address2 = updatedFormValues.address2;
    if (updatedFormValues.city !== '') addressDto.city = updatedFormValues.city;
    if (updatedFormValues.state !== '')
      addressDto.state = updatedFormValues.state;
    if (updatedFormValues.postal_code !== '')
      addressDto.postal_code = updatedFormValues.postal_code;
    if (
      updatedFormValues.address1 !== '' &&
      updatedFormValues.city !== '' &&
      updatedFormValues.state !== '' &&
      updatedFormValues.postal_code !== '' &&
      updatedFormValues.country !== ''
    ) {
      addressDto.country = updatedFormValues.country;
    }
    const isAddressComplete =
      updatedFormValues.address1 &&
      updatedFormValues.city &&
      updatedFormValues.country &&
      updatedFormValues.state &&
      updatedFormValues.postal_code
        ? true
        : false;
    const updatePatientRecord: UpdatePatientRecordDto = {
      first_name: updatedFormValues.first_name,
      last_name: updatedFormValues.last_name,
      date_of_birth: formatDateStringAsISO(
        updatedFormValues.date_of_birth,
        DateTimeFormat.USDateFormat,
      ),
      gender: updatedFormValues.gender,
      address: isAddressComplete ? addressDto : null,
    };

    handleSave(updatePatientRecord);
  };

  return (
    <View>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('first-name')}
              name="first_name"
              value={formValues.first_name}
              rules={{
                required: getText('first-name-is-required'),
                validate: {
                  value: () => {
                    return validate.isName(formValues.first_name)
                      ? true
                      : getText('first-name-is-not-valid');
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('last-name')}
              name="last_name"
              rules={{
                required: getText('last-name-is-required'),
                validate: {
                  value: () => {
                    return validate.isName(formValues.last_name)
                      ? true
                      : getText('last-name-is-not-valid');
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('birth-date')}
              name="date_of_birth"
              rules={{
                required: getText('birth-date-is-required'),
                validate: {
                  validateDOB: (value: string) => {
                    return validate.isDateOfBirth(
                      formatDate(value, DateTimeFormat.USDateFormat),
                    )
                      ? true
                      : getText('date-is-not-valid');
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="date"
            />
          </Form.Column>
        </Form.Row>
        {record && relationshipProps && relationshipProps.length && (
          <Form.Row>
            <Form.Column>
              <DropdownSelect
                options={relationshipProps}
                fieldName="relationship"
                label={getText('relation-to-primary-user') + '*'}
                labelInlined={true}
              ></DropdownSelect>
            </Form.Column>
          </Form.Row>
        )}
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
            <Text> {getText('gender')}</Text>
            <RadioButtonGroupField
              name="gender"
              testID={'gender-test-id'}
              values={[
                {
                  text: getText('male'),
                  value: Gender.Male,
                },
                {
                  text: getText('female'),
                  value: Gender.Female,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Address form={form}></Address>
        <Form.Actions>
          <Button
            onPress={form.handleSubmit(handleSubmit)}
            hierarchy="primary"
            loading={status === 'loading'}
            logger={{ id: 'personal-info-save-button' }}
          >
            {getText('save')}
          </Button>
        </Form.Actions>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
  row: {
    alignContent: 'stretch',
  },
}));
