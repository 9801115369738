import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { GestureResponderEvent, Platform } from 'react-native';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { PatientUnderCareList } from './PeopleUnderCareList';
import { AddPatientUnderCareEmail } from './AddPatientUnderCareEmail';
import { AddPatientUnderCareInfo } from './AddPatientUnderCareInfo';
import { AddPatientUnderCareRequest } from './AddPatientUnderCareRequest';
import { PatientUnderCare } from './PatientUnderCare';
import { PlusIcon } from 'assets/icons';
import { AddMinorOrPetPatient } from './AddMinorOrPetPatient';
import { AddMinorOrPatientUnderCareRequestSubmitted } from './AddMinorOrPetPatientRequestSubmitted';

const Stack = createStackNavigator<PatientUnderCareStackParamList>();

export const PatientUnderCareNavigation: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = ({}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: PatientUnderCareNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="patient-under-care"
          component={PatientUnderCare}
          options={{
            title: getText('people-under-care'),
            header: (props) => (
              <PatientUnderCareNavigationHeader
                showAddIcon={false}
                showBack={true}
                {...props}
              />
            ),
          }}
        />
        <Stack.Screen
          name="patient-under-care-list"
          component={PatientUnderCareList}
          options={{
            title: getText('people-under-care'),
            header: (props) => (
              <PatientUnderCareNavigationHeader
                showAddIcon={true}
                showBack={true}
                {...props}
              />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-info"
          component={AddPatientUnderCareInfo}
          options={{
            title: getText('add-patient'),
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-email"
          component={AddPatientUnderCareEmail}
          options={{
            title: getText('add-patient'),
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-confirmation"
          component={AddPatientUnderCareRequest}
          options={{
            title: getText('add-patient'),
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-minor-or-pet-patient"
          component={AddMinorOrPetPatient}
          options={{
            title: getText('add-patient'),
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-minor-or-pet-request-submitted"
          component={AddMinorOrPatientUnderCareRequestSubmitted}
          options={{
            title: getText('add-patient'),
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={false} {...props} />
            ),
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface PatientUnderCareProps extends StackHeaderProps {
  showAddIcon?: boolean;
  showBack?: boolean;
}

export type PatientUnderCareStackParamList = {
  'patient-under-care': undefined;
  'add-patient-under-care': undefined;
  'add-patient-under-care-info': undefined;
  'add-patient-under-care-email': undefined;
  'add-patient-under-care-confirmation': undefined;
  'patient-under-care-list': undefined;
  'add-minor-or-pet-patient': undefined;
  'add-minor-or-pet-request-submitted': undefined;
};

export type PatientUnderCareStackNavigationProp =
  StackNavigationProp<PatientUnderCareStackParamList>;

export const PatientUnderCareNavigationHeader: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = (props) => {
  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  const handleActionPress = () => {
    props.navigation.navigate('add-patient-under-care-info');
  };
  // TODO: follow up cards will make this dynamic based on the route as we add other routes
  return (
    <NativeHeader
      actionLabel={getText('add')}
      actionIcon={PlusIcon}
      onActionPress={handleActionPress}
      onBack={handleBack}
      showAction={props.showAddIcon}
      showBack={props.showBack}
      title={props.options.title}
    />
  );
};
