import {
  AppointmentTypeDto,
  AppointmentTypeSlotDto,
  BookingDto,
  CreateBookingDto,
  SubmissionDto,
  UpdateBookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import appointmentService from '../../../api/appointment-service';
import formsService from '../../../api/forms-service';
import patientService from '../../../api/patient-service';
import { useFillInFormStore } from '../../fill-in-form/fill-in-form-store';
import { BookingStep, useBookAppointmentState } from './book-appointment-store';
import { usePatientRecordState } from '../../account/patient/patient-store';

export const getAvailableSlots = async (
  pharmacyId: string,
  locationId: string,
  appointmentTypeId: string,
  startDate: string,
  endDate: string,
) => {
  useBookAppointmentState.setState({ slotsStatus: 'loading' });

  try {
    const response = await appointmentService.getAvailableAppointmentSlots(
      pharmacyId,
      locationId,
      appointmentTypeId,
      startDate,
      endDate,
    );

    useBookAppointmentState.setState({
      slotsStatus: 'success',
      slots: response,
    });
  } catch (error) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-loading-slots'),
      },
      slotsStatus: 'error',
      processError: true,
    });
  }
};

export const setSelectedDate = (selectedDate?: string) => {
  useBookAppointmentState.setState({ selectedDate });
};

export const setStep = (step: number) => {
  const { stepsMap } = useBookAppointmentState.getState();
  const stepName = stepsMap[step];

  useBookAppointmentState.setState({ step, stepName });
};

export const setStepByName = (stepName: BookingStep) => {
  const { stepsMap } = useBookAppointmentState.getState();
  const step = stepsMap.indexOf(stepName);

  useBookAppointmentState.setState({ step, stepName });
};

export const setTimeSlots = (timeSlots: AppointmentTypeSlotDto[]) => {
  useBookAppointmentState.setState({ timeSlots });
};

export const setSelectedSlot = (
  selectedSlot: AppointmentTypeSlotDto | undefined,
) => {
  useBookAppointmentState.setState({ selectedSlot });
};

export const enableNextButton = () => {
  useBookAppointmentState.setState({ nextButtonStatus: 'enabled' });
};

export const disableNextButton = () => {
  useBookAppointmentState.setState({ nextButtonStatus: 'disabled' });
};

export const getPatientRecord = async (patientRecordId: string) => {
  useBookAppointmentState.setState({
    patientRecordStatus: 'loading',
  });

  try {
    const response = await patientService.findPatientRecord(patientRecordId);

    useBookAppointmentState.setState({
      patientRecord: response,
      patientRecordStatus: 'idle',
    });
  } catch (e) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      patientRecordStatus: 'error',
      processError: true,
    });
  }
};

export const setLocation = (location: PharmacyLocationDto) => {
  useBookAppointmentState.setState({ location });
};

export const clearLocation = () => {
  useBookAppointmentState.setState({ location: undefined });
};

export const setBooking = (booking: BookingDto | undefined) => {
  useBookAppointmentState.setState({ booking });
};

export const createBooking = async (
  pharmacyId: string,
  locationId: string,
  booking: CreateBookingDto,
) => {
  useBookAppointmentState.setState({ bookingStatus: 'loading' });

  try {
    const response = await appointmentService.createBooking(
      pharmacyId,
      locationId,
      booking,
    );

    useBookAppointmentState.setState({
      booking: response,
      bookingStatus: 'success',
    });
  } catch (error) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-booking-appointment'),
      },
      bookingStatus: 'error',
      processError: true,
    });
  }
};

export const updateBooking = async (
  pharmacyId: string,
  locationId: string,
  bookingId: string,
  booking: UpdateBookingDto,
) => {
  useBookAppointmentState.setState({ bookingStatus: 'loading' });

  try {
    const response = await appointmentService.updateBooking(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    useBookAppointmentState.setState({
      booking: response,
      bookingStatus: 'success',
    });
  } catch (error) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-rescheduling-appointment'),
      },
      bookingStatus: 'error',
      processError: true,
    });
  }
};

export const setShowBookAppointment = (showBookAppointment: boolean) => {
  useBookAppointmentState.setState({ showBookAppointment });
};

export const setIsReschedule = (isReschedule: boolean) => {
  useBookAppointmentState.setState({ isReschedule });
};

export const setAppointmentType = (
  appointmentType: AppointmentTypeDto | undefined,
) => {
  useBookAppointmentState.setState({ appointmentType });
};

export const setBookingId = (bookingId: string | undefined) => {
  useBookAppointmentState.setState({ bookingId });
};

export const resetBookAppointment = () => {
  useBookAppointmentState.setState({
    bookingStatus: 'idle',
    selectedDate: undefined,
    selectedSlot: undefined,
    step: 0,
    stepName: BookingStep.Patient,
    stepsMap: [
      BookingStep.Patient,
      BookingStep.Date,
      BookingStep.Time,
      BookingStep.Confirmation,
    ],
    booking: undefined,
    nextButtonStatus: 'disabled',
    appointmentType: undefined,
    isReschedule: false,
    selectedPatient: undefined,
    selectedLocationPatientRecordId: undefined,
    processError: false,
    selectedLocation: undefined,
    locationOverride: undefined,
    formHtmlContent: undefined,
    formSubmissions: [],
    currentForm: 0,
    appointmentTypeDetails: undefined,
    appointmentTypeDetailsStatus: 'idle',
  });
  useFillInFormStore.setState({
    status: 'idle',
  });
};

export const getAvailableAppointmentLocations = async (
  pharmacyId: string,
  appointmentGroupId: string,
) => {
  useBookAppointmentState.setState({
    availableAppointmentLocationsStatus: 'loading',
  });

  try {
    const response = await appointmentService.findAvailableLocations(
      pharmacyId,
      appointmentGroupId,
    );

    useBookAppointmentState.setState({
      availableAppointmentLocations: response,
      availableAppointmentLocationsStatus: 'success',
    });
  } catch (error) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-getting-locations'),
      },
      availableAppointmentLocationsStatus: 'error',
    });
  }
};

export const getPatientsUnderCare = async (patientId: string) => {
  useBookAppointmentState.setState({ patientsUnderCareStatus: 'loading' });
  const { recordsUnderCare, recordUnderCareRequests } = usePatientRecordState();
  try {
    useBookAppointmentState.setState({
      patientsUnderCareStatus: 'success',
      patientsUnderCare: recordsUnderCare,
      patientsUnderCareRequests: recordUnderCareRequests,
    });
  } catch (error) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-loading-patients-under-care'),
      },
      patientsUnderCareStatus: 'error',
    });
  }
};

export const setSelectedPatient = (
  selectedPatient: PatientRecordDto | RecordUnderCareDto | undefined,
) => {
  useBookAppointmentState.setState({ selectedPatient });
};

export const setProcessError = (processError: boolean) => {
  useBookAppointmentState.setState({ processError });
};

export const setLocationOverride = (
  locationOverride: PharmacyLocationDto | undefined,
) => {
  useBookAppointmentState.setState({ locationOverride });
};

export const setSelectedLocation = (
  selectedLocation: PharmacyLocationDto | undefined,
) => {
  useBookAppointmentState.setState({ selectedLocation });
};

export const setStepsMap = (stepsMap: BookingStep[]) => {
  useBookAppointmentState.setState({ stepsMap });
};

export const setSelectedLocationPatientRecordId = (
  selectedLocationPatientRecordId: string,
) => {
  useBookAppointmentState.setState({ selectedLocationPatientRecordId });
};

export const initLocationPatientRecord = async (
  patientRecordId: string,
  locationId: string,
) => {
  useBookAppointmentState.setState({
    formsStatus: 'loading',
  });

  try {
    const response =
      await patientService.patientRecordInitLocationPatientRecord(
        patientRecordId,
        {
          location_id: locationId,
        },
      );

    const locationPatientRecord = response.location_patient_records.find(
      (record) => record.location_id === locationId,
    );

    useBookAppointmentState.setState({
      selectedLocationPatientRecordId: locationPatientRecord?.location_id,
      formsStatus: 'success',
    });
  } catch (e) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      formsStatus: 'error',
      processError: true,
    });
  }
};

export const getFormContent = async (
  formId: string,
  locationId: string,
  patientRecordId: string,
): Promise<void> => {
  useBookAppointmentState.setState({ formsStatus: 'loading' });

  try {
    const response = await formsService.getPrivateForm(
      locationId,
      formId,
      patientRecordId,
    );

    useBookAppointmentState.setState({
      formsStatus: 'idle',
      formHtmlContent: response,
    });
  } catch (e) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-loading-form'),
      },
      formsStatus: 'error',
    });
  }
};

export const setFormSubmissions = (formSubmissions: SubmissionDto[]) => {
  useBookAppointmentState.setState({ formSubmissions });
};

export const setCurrentForm = (currentForm: number) => {
  useBookAppointmentState.setState({ currentForm });
};

export const getAppointmentTypeDetails = async (
  pharmacyId: string,
  locationId: string,
  appointmentTypeId: string,
) => {
  useBookAppointmentState.setState({
    appointmentTypeDetailsStatus: 'loading',
  });
  try {
    const response = await appointmentService.findAppointmentTypeById(
      pharmacyId,
      locationId,
      appointmentTypeId,
    );

    useBookAppointmentState.setState({
      appointmentTypeDetails: response,
      appointmentTypeDetailsStatus: 'success',
    });
  } catch (error) {
    useBookAppointmentState.setState({
      error: {
        message: getText('error-loading-appointment-type'),
      },
      appointmentTypeDetailsStatus: 'error',
      processError: true,
    });
  }
};
